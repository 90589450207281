declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const login = (
  userId: string,
  orgId: string,
  auth0: boolean,
  circle: boolean
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'login',
    userId: userId,
    orgId: orgId,
    auth0: auth0,
    circle: circle,
  });
};
